import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/feature-auth/CreateProfile.page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/feature-auth/Login.page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/feature-auth/otp/Otp.page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/feature-auth/SignUp.page.tsx");
